import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image as BootstrapImage, Navbar, Nav, Dropdown } from 'react-bootstrap';
import '../Homepage.css';
import './Essays.css';
import navbarIcon from '../assets/Navbar.svg';
import 'bootstrap/dist/css/bootstrap.min.css';

function Essays() {
  // Your existing state and useEffect hooks

  return (
    <Container>
      <Row className="header d-flex align-items-center justify-content-between">
        <Col xs={4}>
          <Navbar>
            <Nav className="ml-md-0 mr-md-auto">
              {/* Display the dropdown on small screens and hide it on large screens */}
              <Dropdown className="d-md-none">
                <Dropdown.Toggle variant="link" className="custom-dropdown" id="dropdown-basic">
                  <BootstrapImage src={navbarIcon} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/">Home</Dropdown.Item> {/* Updated to Home */}
                  {/* Add other Dropdown.Items as needed */}
                </Dropdown.Menu>
              </Dropdown>

              {/* Hide the links on small screens and display them on large screens */}
              <Nav.Link as={Link} to="/" className="d-none d-md-block ml-auto custom-link">Home</Nav.Link> {/* Updated to Home */}
              {/* Add other Nav.Links as needed */}
            </Nav>
          </Navbar>
        </Col>
        {/* Other columns */}
      </Row>

      {/* Other components and rows above */}

      <article className="blog-post">
        <h2 className="blog-post-title">What If It Hits?</h2>
        <p className="blog-post-meta">3/2/2024</p>
        <p className="blog-post-content">
          Magic tricks don't always work—actually, some of the best magic tricks fail most of the time. There's a trick I used to perform where I appear to read someone's mind. The secret to doing the trick was simple: I simply try to guess what they are thinking of.
        </p>
        <p className="blog-post-content">
          The trick only succeeded 15% of the time. Yet that slim chance was all that was needed: If the trick failed, I simply would downplay it and move onto another piece. However, if the trick "hit"—it would be one of the most impossible tricks I performed that week.
        </p>
        <p className="blog-post-content">
          Constantly asking the question "What if it hits?" has shaped my approach to taking risks. It began with me reaching out in high school to my heroes in magic, knowing the chances of a response were slim, but understanding that a single reply could significantly change the trajectory of my magic. Today, this mindset has led me to explore fascinating, yet currently overlooked, areas of technology. Perhaps being at the very frontier of crypto, a sector of technology many people don't believe in, is off base. However, what if I'm correct? What if it hits?
        </p>
        <p className="blog-post-content">
          It's not that I'm indifferent to failure; rather, I'm captivated by the possibility of success. Knowing what happens if I succeed makes the fear of failure less daunting, motivating me to persist in my efforts.
        </p>
        <p className="blog-post-content">
          Furthermore, I've discovered that we often very much overestimate the chance we fail and underestimate our likelihood of success. I know most people don't believe in manifestation, but I've found belief in oneself to be so powerful: with the thoughtful approach and by constantly taking chances, reality bends to your will and you will succeed.
        </p>
      </article>

{/* Other components and rows below */}
      {/* Other rows */}
    </Container>
  );
}

export default Essays;
